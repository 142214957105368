<template>
    <v-container>
        <v-card>
            <Table :items="arr" :filtro="true" :loading="loading"/>
        </v-card>
    </v-container>
</template>
<script>
import AppEtlService from '@/service/AppEtlService'
const api = new AppEtlService();
export default {
    components: { 
        Table:()=>import('@/etl/components/graficos/Table') 
    },
    name:"Kpis",
    data:()=>({
        arr:[],
        loading:false,
    }),
    methods:{ 
        async get_(query,params){
            this.loading = true
            return await api.get_query(query,params)
            .then( response => { 
                this.arr = response.data
            })
            .catch(error => { console.log(error) })
            .finally( () => { this.loading = false })
        },
    }, 
    mounted(){
        this.get_('get_agendamentos',[])
    }
}
</script>